<template>
  <div style="height: 100%;">
    <div class="index-box" v-if="isMobile === false">
      <el-container>
        <el-header>
          <CaseHeads></CaseHeads>
        </el-header>
        <el-container style="margin-top: -60px">
          <el-aside width="240px" style="padding-top: 60px;">
            <CaseMenu @mentList="transferMentList"></CaseMenu>
          </el-aside>
          <el-container style="padding-top: 60px;">
            <el-main>
              <router-view :transferMent="transferMent"></router-view>
            </el-main>
            <el-footer style="height: auto;">
              <MenuFoot></MenuFoot>
            </el-footer>
          </el-container>
        </el-container>
      </el-container>
    </div>
    <div class="index-box2" v-else>
      <el-container class="main-box2">
        <el-main style="padding: 10px;">
<!--          <div class="headr-box">-->
<!--            <div class="headr-go" @click="goNav">-->
<!--              <i class="el-icon-arrow-left"></i>-->
<!--              <span class="pc-show">返回提交页</span>-->
<!--              <span class="h5-show">返回</span>-->
<!--            </div>-->
<!--            <UserLogin class="user-login" @userInfoSelf="acceptUserLogin"></UserLogin>-->
<!--          </div>-->
          <router-view></router-view>
        </el-main>
      </el-container>
    </div>
  </div>
</template>
<script>
import CaseHeads from '../../components/caseHeads/caseHeads.vue'
import CaseMenu from '../../components/caseMenu/caseMenu.vue'
import UserLogin from "@/components/userLogin/index.vue"
import MenuH5 from "@/components/menuH5/menuH5.vue"
import MenuFoot from "@/components/menuFoot/index.vue"

export default {
  components:{
    MenuFoot,
    MenuH5,
    UserLogin,
    CaseHeads,
    CaseMenu
  },
  data() {
    return {
      // activeValue: 10,
      transferMent: [],
      windowWidth: document.body.clientWidth,
      isMobile: false
    }
  },
  created() {
    this.isMobile = this.windowWidth < 901;
  },
  watch: {
    // 监听页面宽度
    windowWidth(newWidth) {
      this.isMobile = newWidth < 901;
    }
  },
  mounted() {
    window.onresize = () => {
      return (() => {
        this.windowWidth = document.documentElement.clientWidth
      })()
    }
  },
  methods: {
    transferMentList(val){
      this.transferMent = val
    },
    acceptUserLogin(msg){
      if (msg && msg.user === false){
        this.userInfo = this.$options.data().userInfo
        this.$emit('userInfo',msg)
      }else {
        this.userInfo = msg.info
        this.$emit('userInfo',msg)
      }
    },
  }
}
</script>
<style scoped lang="less">
.index-box {
  display: block;
  height: 100%;
  width: 100%;

  .el-container {
    height: 100%;
    background: url("../../assets/images/main-bg2.png") no-repeat center -104px;
    background-size: cover;
  }
}
.index-box2{
  display: block;
  width: 100%;
  height: 100vh;
  background: url(../../assets/images/bg.jpg) no-repeat right 0;
  background-size: cover;
  .main-box2{
    width: 100%;
    height: 100%;
  }
}
</style>
