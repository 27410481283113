import request from '@/utils/request'

export default {
  //创建订单(需要Ticket)
  postAIpptCreateOrder(data,key,time,md5){
    return request({
      url: '/ai/ppt/create/order',
      method: 'post',
      headers: {
        "ticket-key": key,
        timestamp: time,
        sgin: md5
      },
      data: data
    })
  },
  //获取支持的AIPPT支持的页数
  getAIpptpPageNum(){
    return request({
      url: '/ai/ppt/pageNum',
      method: 'get'
    })
  },
  //获取支持的AIPPT模板
  getAIpptTemplateList(){
    return request({
      url: '/ai/ppt/template/list',
      method: 'get'
    })
  },
  //上传AIPPT源文档(需要Ticket)
  postAIpptUpload(headers,formData,key,time,md5){
    return request({
      url: '/ai/ppt/upload',
      method: 'post',
      headers: {
        ...headers,
        "ticket-key": key,
        timestamp: time,
        sgin: md5
      },
      data: formData
    })
  }
}
