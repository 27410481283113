<template>
  <div class="form-box">
    <FormTitle :infoName="infoName" />
    <div class="form-list">
      <el-form :model="ruleForm" ref="ruleForm" label-position="top" class="demo-ruleForm" @submit.native.prevent>
        <el-form-item label="请上传AIGC检测报告（需要全文报告单）" class="form-item-pc-show">
          <div class="upload-item">
            <div v-loading="openUploadLoading" v-if="!uploadShow">
              <div class="upload-btn" @click="openUpload">
                <div class="upload-text">
                  <i class="el-icon-upload"></i>
                  <span>上传检测报告</span>
                  <span>(中文)</span>
                </div>
                <div class="upload-format">
                  <span>支持：</span>
<!--                  <img class="tip-icon" alt="txt" :src="iconTxt">-->
<!--                  <img class="tip-icon" alt="word" :src="iconWord">-->
                  <img class="tip-icon" alt="pdf" :src="iconPdf">
                </div>
              </div>
              <div class="upload-tip">
                <span>支持报告：</span>
                <img src="@/assets/images/aigc-check-tip.png" alt="aigc">
              </div>
<!--              <div class="upload-tip">-->
<!--                <span>无需报告也能降重，降完必过AIGC检测</span>-->
<!--              </div>-->
            </div>

            <div class="upload-btn" v-if="uploadShow"><!--uploadShow-->
              <div class="upload-format">
                <img class="tip-icon" alt="txt" :src="iconTxt" v-if="uploadFile.type && uploadFile.type.toLowerCase() === 'txt'">
                <img class="tip-icon" alt="word" :src="iconWord" v-if="uploadFile.type && uploadFile.type.toLowerCase() === 'docx' || uploadFile.type.toLowerCase() === 'doc'">
                <img class="tip-icon" alt="pdf" :src="iconPdf" v-if="uploadFile.type && uploadFile.type.toLowerCase() === 'pdf'">
                <span>{{uploadFile.value ? uploadFile.value.name : ''}}</span>
                <div class="upload-delete" v-if="uploadFile.state !== 0" @click="resetUpload">
                  <i class="el-icon-delete"></i>
                  <span>删除</span>
                </div>
              </div>
              <el-progress :percentage="uploadProgressValue" v-if="uploadFile.state === 0"></el-progress>
              <div class="upload-tip-span" v-if="uploadFile.state === 0">
                <span>正在解析中，请稍后~</span>
              </div>
              <div class="upload-tip-span" v-if="uploadFile.state === 1">
                <i class="el-icon-success" style="color: #5c9eff"></i>
                <span>解析成功！开始降重吧！</span>
              </div>
              <div class="upload-tip-span" v-if="uploadFile.state === 2">
                <i class="el-icon-error" style="color: #e31c1c"></i>
                <span>解析失败！请重新上传！</span>
              </div>
              <div class="h5-upload-delete" v-if="uploadFile.state !== 0" @click="resetUpload">
                <i class="el-icon-delete"></i>
                <span>删除</span>
              </div>
            </div>

            <div class="check-paper-tip" v-if="checkPaperShow">
              <div class="check-paper-title">
                <i class="el-icon-sunny icon-left"></i>
                <span>选择AIGC查重报告类型</span>
                <i class="el-icon-close icon-right" @click="closePaper"></i>
              </div>
              <div class="check-paper-list">
                <el-button class="check-paper-span"
                           type="primary"
                           size="small"
                           plain
                           round
                           v-for="item in reportList"
                           @click="checkItem(item)">{{item.title}}</el-button>
              </div>
            </div>
          </div>
        </el-form-item>
      </el-form>
    </div>
    <div class="form-btn">
      <el-checkbox class="form-check" v-model="btnInfo.checked">{{btnInfo.title}}</el-checkbox>
      <el-button style="width: 180px" type="primary" :disabled="!btnInfo.checked" @click="submitForm">立即降重</el-button>
    </div>

    <el-upload ref="upload"
               action="#"
               class="upload-demo"
               style="display: none;"
               :limit="1"
               :accept="uploadFile.accept"
               :show-file-list="false"
               :http-request="uploadSmallFile"
               :before-upload="beforeAvatarUpload">
      <el-button type="primary" size="small">{{'真实上传按钮（隐藏）'}}</el-button>
    </el-upload>

    <NoticeWrapper />
  </div>
</template>

<script>
import AigcApi from "@/api/aigc"
import { orderMixin } from '@/utils/orderMixin'
import DrawerH5 from '@/components/drawerH5/drawerH5.vue'
import FormTitle from "@/components/FormTitle/index.vue"
import NoticeWrapper from "@/components/NoticeWrapper/index.vue"
import TitleInfoBox from "@/components/titleInfoBox/index.vue"
export default {
  components: {TitleInfoBox, NoticeWrapper, FormTitle, DrawerH5 },
  mixins: [orderMixin],
  props: ['infoName'],
  data() {
    return {
      ruleForm: {
        creationDocId: '',
        fileKey: '',
        source: 0
      },
      btnInfo: {
        title: '我已阅读并同意: 所有的范文仅用于格式和思路参考',
        checked: true
      },
      iconTxt: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAOCAYAAAAfSC3RAAAAAXNSR0IArs4c6QAAAG5JREFUOE9j3Hfs/H8GMgAjSKOjpQFJWvcfv8BAPY2Xb95n+Pj5G4oLxIQFGNQUpeFiWG08cuYqw9v3nxhu3HsMVqihJMsgKyXKYKStgl8jTHbj7uNgpr+rJYb/8fpxVCM1EwAxaQ8eHcQoRlcDAL9Xc4srMAYvAAAAAElFTkSuQmCC',
      iconWord: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAOCAYAAAAfSC3RAAAAAXNSR0IArs4c6QAAASZJREFUOE9jPHPvw38GMgAjSKOxIj9JWs/e/8gwwBobt39gsFRgZ3DT5GSYceQzw5uv/xhq3PkZjt//ybD5yjeGNl9BsJcwnLrq/FeG99/+MaRb8zLkrn7H8PXXP4Z50SIMIPFvv/4zJJjzYNd49vEvhiWnvzK0+wowZK16y8DGwsjQ5CXAMOvYFwZbZXYGexUO7Bo/fv/HkLT0LUO5Kx/DwTs/GbjZGBlURFgYlp39ytDlL8ggxsuMXSNINGL+awZHNQ4GOUEWBg4WRobrL38zHLn7g2FRrAgDCzMjbo1Vm98z3Hz1h2FCkCADFxsjQ8bKdwwyAiwM/UGQgMEaOCDBFWe/Mqy9+I1heTzEhuiFbxjsVdgZ0qx58WskJglRnnKIsQVdDQCEraptTema1QAAAABJRU5ErkJggg==',
      iconPdf: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAOCAYAAAAfSC3RAAAAAXNSR0IArs4c6QAAARFJREFUOE+d0ruKwlAQgOE/l12za2XnDSSFdlYivoMPYJdGSOVD2FjYiG9gY6VWtuIb2GinjYiNYCWCZr2cZEkWFoKJZneaU5zzzZkZRjoPBg7/CMmFH7Xan6g1HBII74sF19GImGmi5HIPSUPhbTJBrNeopRJquRwdiuWSS6/Hu2GgFovRIUJwbjbRGg3kVCo6tHc7vrpd5HwerV4HRfHh0B6v4zFSIoGYz5HTaaRMBk4nL5E7rEDoHI9Y7TZv1Sr2dotzOGBvNkjZLM5+z2er9Qjvsxm36RRZ11ErFRRd90p0Sxerlfe7Uij44aXf/8mcTKKZ5tOF8JVqdTpI8Tgxw/DOZxE6nFf79wtfPQy6/wZzWJ3xMmOuUQAAAABJRU5ErkJggg==',
      reportList: [],
      openUploadLoading: false,
      checkPaperShow: false,
      uploadFile: {
        value: null,
        source: null,
        accept: '.pdf',
        type: '',
        state: 0    // 0默认，1成功，2失败
      },
      uploadProgressValue: 0,
      timer: null,
      uploadShow: false
    }
  },
  created() {},
  methods: {
    // 上传组件钩子
    beforeAvatarUpload(file){
      // console.log(file)
      this.uploadFile.value = file
      this.uploadFile.type = file.name.substring(file.name.lastIndexOf('.')+1)
    },
    // 上传附件
    uploadSmallFile(){
      this.closePaper()
      this.openUploadShow()
      const formData = new FormData()
      formData.append('file', this.uploadFile.value)
      formData.append('source', this.uploadFile.source)
      const headers = {'Content-Type': 'multipart/form-data'}
      let time = new Date().getTime()
      let key = localStorage.getItem('key')
      let ticket = localStorage.getItem('ticket')
      let setOf = ticket + time + ticket + ticket + time + ticket
      let infoMd5 = this.$md5(setOf)
      AigcApi.postAigcUpload(headers,formData,key,time,infoMd5).then(res => {
        // console.log(res.data)
        this.ruleForm.fileKey = res.data
        this.uploadFile.state = 1
      }).catch(()=>{
        this.uploadFile.state = 2
      }).finally(()=>{
        clearInterval(this.timer);
        this.timer = null
      })
    },
    getAigcReportList(){
      let _this = this
      _this.openUploadLoading = true
      AigcApi.getAigcReportList().then(res => {
        _this.reportList = res.data
        _this.openUploadLoading = false
        _this.checkPaperShow = true
      }).catch(() => {
      })
    },
    // 打开选择弹窗
    openUpload(){
      this.getAigcReportList()
    },
    closePaper(){
      this.checkPaperShow = false
    },
    checkItem(item){
      // console.log(item)
      this.uploadFile.accept = item.fileSuffix || '.pdf,.PDF'
      this.uploadFile.source = item.source
      // 触发隐藏的真实上传按钮的点击事件
      this.$refs.upload.$el.querySelector('.el-upload__input').click()
    },
    // 模拟进度条
    setProgress(){
      this.uploadProgressValue = 0
      this.timer = setInterval(() => {
        this.uploadProgressValue += 1;
        // 记得清除定时器
        if (this.uploadProgressValue === 99) {
          clearInterval(this.timer);
          this.timer = null
        }
      }, 300)
    },
    openUploadShow(){
      this.uploadShow = true
      this.setProgress()
    },
    resetUpload(){
      this.uploadShow = false
      this.$refs.upload.clearFiles()
      this.ruleForm.fileKey = ''
      this.uploadFile = {
        value: null,
        source: null,
        accept: '.pdf',
        type: '',
        state: 0
      }
    },
    submitForm() {
      let _this = this
      if(!_this.ruleForm.fileKey) return _this.$message.error('请上传AIGC检测报告!')
      _this.ruleForm.source = _this.uploadFile.source
      _this.ruleForm.creationDocId = 19
      if(localStorage.getItem('shareCode')){
        _this.ruleForm.shareCode = localStorage.getItem('shareCode')
      }
      _this.createOrder(_this.ruleForm)
    },
    createOrder(data){
      let _this = this
      let dataParams = {
        creationDocId: 19,
        creationDocCode: 'AIGC',
        info: null
      }
      let time = new Date().getTime()
      let key = localStorage.getItem('key')
      let ticket = localStorage.getItem('ticket')
      let setOf = ticket + time + ticket + ticket + time + ticket
      let infoMd5 = _this.$md5(setOf)
      AigcApi.postAigcCreateOrder(data,key,time,infoMd5).then(res => {
        dataParams.info = res.data.id
        _this.$router.push({name: 'IntelligentPay', params: dataParams})
      }).catch(() => {
      })
    }
  }
}
</script>

<style lang="less" scoped>
@import "@/assets/css/pageContent";
/deep/ .upload-demo{
  .el-upload__tip{
    margin: 0;
    height: 24px;
    line-height: 24px;
  }
  .el-upload-list{
    overflow: auto;
    max-height: 160px;
  }
}
.upload-item{
  position: relative;
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  padding: 30px 0;
  border: 1px dashed #d4d4d4;
  border-radius: 10px;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .upload-btn{
    min-width: 220px;
    padding: 20px;
    border: 1px solid rgba(70, 122, 242, 0.2);
    border-radius: 10px;
    background-color: rgba(70, 122, 242, 0.04);
    cursor: pointer;
    span{
      line-height: 20px;
      font-size: 14px;
      color: #5c9eff;
    }
    .upload-text{
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      i{
        font-size: 18px;
        color: #5c9eff;
      }
    }
    .upload-format{
      margin-top: 6px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      .tip-icon{
        margin-right: 8px;
        width: 14px;
        height: 14px;
      }
      > span{
        color: #999999;
      }
      .upload-delete{
        margin-left: 10px;
        color: #e31c1c;
        cursor: pointer;
        i{
          font-size: 18px;
        }
        span{
          margin-left: 6px;
          color: #e31c1c;
        }
      }
    }
    .upload-tip-span{
      margin-top: 10px;
      line-height: 20px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      i{
        margin-right: 6px;
        font-size: 16px;
      }
      span{
        font-size: 12px;
        color: #3c3c3c;
      }
    }
    .h5-upload-delete{
      display: none;
    }
    /deep/ .el-progress{
      .el-progress-bar{
        padding-right: 30px;
        margin-right: -36px;
      }
      .el-progress__text{
        font-size: 12px !important;
      }
    }
  }
  .upload-tip{
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    span{
      font-size: 14px;
      color: #999999;
    }
    img{
      width: 220px;
      height: auto;
    }
  }
  .check-paper-tip{
    position: absolute;
    z-index: 20;
    top: -50px;
    left: 50%;
    width: 470px;
    padding: 20px;
    margin-left: -235px;
    border-radius: 10px;
    background-color: #ffffff;
    box-shadow: 0 3px 10px rgba(0,0,0,0.2);
    .check-paper-title{
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      i{
        font-size: 18px;
      }
      span{
        line-height: 20px;
      }
      .icon-left{
        color: #e6a23c;
      }
      .icon-right{
        margin-left: auto;
        cursor: pointer;
      }
      .icon-right:hover{
        color: #5c9eff;
      }
    }
    .check-paper-list{
      width: 100%;
      /deep/ .el-upload{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        flex-wrap: wrap;
      }
      .check-paper-span{
        margin: 20px 3% 0 0;
      }
    }
  }
}
@media screen and (max-width: 900px) {
  .upload-demo{
    width: 100%;
    /deep/ .el-upload{
      width: 100% !important;
      .el-upload-dragger{
        width: 100% !important;
        height: auto !important;
      }
    }
    .el-upload__tip{
      height: auto !important;
      line-height: 20px;
    }
  }
  /deep/ .el-form-item{
    .el-form-item__label{
      line-height: 24px;
    }
  }
  .upload-item{
    .upload-btn{
      width: 90%;
      margin: 0 auto;
      .upload-format{
        .upload-delete{
          display: none;
        }
      }
      .h5-upload-delete{
        display: block;
        margin-top: 10px;
        text-align: center;
        color: #e31c1c;
        cursor: pointer;
        i{
          font-size: 16px;
        }
        span{
          margin-left: 6px;
          color: #e31c1c;
        }
      }
    }
    .upload-tip{
      flex-direction: column;
      img{
        width: 70%;
      }
    }
    .check-paper-tip {
      z-index: 999;
      top: 100px;
      left: -5%;
      width: 110%;
      padding: 20px;
      margin-left: 0;
    }
  }
}
</style>
