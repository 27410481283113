<template>
	<div class="home-bg">
    <div class="notice" v-if="showNotice">
      <span>AI无限改稿</span>重磅上线: 降重、改写、扩写、润色，论文过稿快人一步！
      <i class="el-icon-error" @click="closeNotice"></i>
    </div>
    <div class="home-heads">
      <div class="logo-img">
        <img src="@/assets/images/logo_h.png" alt="小象写作">
      </div>
      <div class="nav-list">
        <ul class="nav">
          <li :class="{'active': index === 0 }"
              v-for="(item,index) in navList2"
              @click="navBtn(item,index)">{{item.name}}</li>
        </ul>
      </div>
      <UserLogin></UserLogin>
    </div>
    <div class="home-tips">
      我们服务已经吸引了超过 <span class="s1">138</span> <span class="s2">万</span> 名用户的信赖与支持
    </div>
    <div class="login-item">
      <div class="login-left">
        <div class="login-title">
          <img src="@/assets/images/home/new-home-title.png" alt="百万大学生都在用的写作平台">
        </div>
        <div class="login-cont-box">
          <ul class="login-cont">
            <li>
              <span class="t">AI 论文：</span>
              <span>输入标题</span>
              <span>自动生成</span>
              <span>结构完整</span>
              <span>10分钟完稿</span>
              <span>高效写作</span>
            </li>
            <li>
              <span class="t">百万案例：</span>
              <span>结构完整</span>
              <span>格式标准</span>
              <span>重复率低</span>
              <span>一键智能写作</span>
            </li>
            <li>
              <span class="t">服务优势：</span>
              <span>精准查重</span>
              <span>参考文献</span>
              <span>论文全文</span>
              <span>论文思路</span>
              <span>AIGC降重</span>
            </li>
          </ul>
        </div>
        <div class="login-cont-box tip-bg">
          <div class="login-tip">
            <img src="@/assets/images/home/new-home-icons.png" alt="">
            <p><span>一键写论文，免费写作大纲</span>，高效、专业、规范的AI助手</p>
            <div class="tips-btn" @click="toPath">立即体验</div>
          </div>
        </div>
      </div>
      <HomeLogin />
    </div>

    <div class="home-title">
      <div class="title-h1">
        <span class="l">小象写作</span>
        <img src="@/assets/images/home/new-home-ai.png" alt="小象写作">
        <span class="r">让天下没有难写的论文</span>
      </div>
      <div class="title-h2">提供灵感来源，让论文写作变轻松</div>
      <div class="title-btn" @click="toPath">
        <span>立即体验</span>
        <img src="@/assets/images/home/new-home-go.png" alt="小象写作">
      </div>
    </div>

    <div class="home-cont-box">
      <div class="home-one">
        <div class="one-span">
          <span></span>
          <span></span>
          <span></span>
        </div>
        <div class="one-cont">
          <div class="one-item">
            <div class="one-txt">
              <h3>智能生成论文大纲</h3>
              <p>小象写作 AI 一键生成论文大纲</p>
              <div>
                <img src="@/assets/images/home/new-home-icon2.png" alt="小象写作">
                <p>只需要输入论文标题，AI即可快速为您生成论文大纲，可修改章节内容， 操作高效快捷！</p>
              </div>
            </div>
            <div class="one-img one-mt40">
              <img src="@/assets/images/home/new-home-x.jpg" alt="小象写作">
            </div>
          </div>
          <div class="one-item">
            <div class="one-img">
              <img src="@/assets/images/home/new-home-s.jpg" alt="小象写作">
            </div>
            <div class="one-txt">
              <h3>智能生成论文大纲</h3>
              <p>小象写作 AI 一键生成论文大纲</p>
              <div>
                <img src="@/assets/images/home/new-home-icon2.png" alt="小象写作">
                <p>只需要输入论文标题，AI即可快速为您生成论文大纲，可修改章节内容， 操作高效快捷！</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="home-title">
      <div class="title-h1">
        <span class="r">为什么都用</span>
        <span class="l ml20">小象写作</span>
      </div>
      <div class="title-h2">能够精准捕捉灵感，高效创作出优秀作品，让写作从此不同</div>
    </div>

    <div class="home-cont-box">
      <div class="home-two">
        <div class="two-left">
          <p class="t1">功能强大的自动化 AI写作工具</p>
          <p class="t2">助您轻松应对各种写作需求</p>
          <p class="t3">让写作变得简单高效</p>
          <ul>
            <li><img src="@/assets/images/home/new-home-dui.png" alt="小象写作">精准搜集相关文献</li>
            <li><img src="@/assets/images/home/new-home-dui.png" alt="小象写作">快速理解文献内容</li>
            <li><img src="@/assets/images/home/new-home-dui.png" alt="小象写作">提供海量论文灵感</li>
          </ul>
        </div>
        <div class="two-right">
          <div class="two-right-item col1">
            <h4>论文灵感</h4>
            <div>
              <p>人工智能精准生成</p>
              <p>高效个性化工作台</p>
            </div>
          </div>
          <div class="two-right-item">
            <h4>海量数据</h4>
            <div>
              <p>超两亿篇中文文献</p>
              <p>高效搜集智能分类</p>
            </div>
          </div>
          <div class="two-right-item col2">
            <h4>提纲推荐</h4>
            <div>
              <p>分析知识投喂文献</p>
              <p>生成完整论文提纲</p>
            </div>
          </div>
          <div class="two-right-item">
            <h4>查重降重</h4>
            <div>
              <p>分段查重降重技术</p>
              <p>准确度高价格优惠</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="home-title">
      <div class="title-h1">
        <span class="r">听听同学们怎么说</span>
      </div>
      <div class="title-h2">操作简单，迅速成文，大大节省了时间和精力，小象写作“创作神器”</div>
    </div>

    <div class="home-cont-box">
      <div class="home-three">

        <div class="swiper-container">
          <div class="swiper-wrapper">
            <div v-for="item in userList" :key="item.id" class="swiper-slide box">
              <div class="top">
                <div class="txt">{{item.txt}}</div>
                <div class="star">
                  <i class="el-icon-star-on"></i>
                  <i class="el-icon-star-on"></i>
                  <i class="el-icon-star-on"></i>
                  <i class="el-icon-star-on"></i>
                  <i class="el-icon-star-on"></i>
                </div>
              </div>
              <div class="bottom">
                <img :src="require(`@/assets/images/avatar/${item.headImgUrl}`)" alt="小象写作">
                <div>
                  <span class="n">{{item.name}}</span>
                  <span>{{item.time}}</span>
                </div>
              </div>
            </div>
          </div>
          <!-- 如果需要分页器 -->
<!--          <div class="swiper-pagination"></div>-->
        </div>

      </div>
    </div>

    <div class="home-title">
      <div class="title-h1">
        <span class="r">学术工具套件</span>
      </div>
      <div class="title-h2">一键成文，精准分析表达，实现个性创作新境界</div>
    </div>

    <div class="home-cont-box">
      <div class="home-four">
        <div class="four-item" v-for="item in toolList" @click="navBtn(item)">
          <div class="item-title">
            <span>{{item.name}}</span>
            <img src="@/assets/images/home/new-home-icon-hot.png" alt="小象写作">
          </div>
          <div class="item-txt">{{item.txt}}</div>
          <div class="item-img">
            <img :src="item.img" alt="小象写作">
          </div>
        </div>
      </div>
    </div>

    <div class="home-foot">
      <img src="@/assets/images/logo-max.png" alt="小象写作">
      <p class="p1">一键成文，写作无忧</p>
      <p class="p2">让每一份文档都优秀、精彩</p>
      <div class="title-btn" @click="toPath">
        <span>立即体验</span>
        <img src="@/assets/images/home/new-home-go.png" alt="小象写作">
      </div>
      <div class="footInfoBtn"></div>
      <MenuFoot style="background-color: transparent;"></MenuFoot>
    </div>

    <!-- 右悬浮 -->
    <suspension class="suspension-pc-show"></suspension>
	</div>
</template>

<script>
  import RequestlogApi from '@/api/requestlog'
  import UserLogin from "@/components/userLogin/index.vue"
  import HomeLogin from "./components/Login/index.vue"
  import UserList from "@/views/home/userList"
  import Swiper from "swiper"
  import "swiper/css/swiper.css"
  import MenuFoot from "@/components/menuFoot/index.vue"
  import suspension from '@/components/suspension/suspension.vue'

	export default {
		name: 'home',
		components:{
      suspension,
      MenuFoot,
      UserLogin,
      HomeLogin
    },
		data() {
			return {
        windowWidth: document.body.clientWidth,
        isMobile: false,
        showNotice: true,
        currentIndex: 0,
        navList2: [{
          active: 0,
          id: 0,
          name: '首页',
          url: '/'
        },{
          active: 0,
          id: 110,
          name: 'AI写作',
          url: '/index'
        },{
          active: 0,
          id: 121,
          name: '案例库',
          url: '/index/caseLibrary'
        },{
          active: 0,
          id: 122,
          name: 'PPT模板',
          url: '/index/pptLibrary'
        },{
          active: 0,
          id: 120,
          name: '订单查询',
          url: '/index/order'
        }],
        userList: [],
        swiper: null,
        toolList: [{
          id: 1,
          name: '毕业论文',
          txt: '免费生成千字大纲，一分钟完成万字专业初稿',
          img: require('@/assets/images/home/new-home-item1.jpg'),
          url: '/index/thesis'
        },{
          id: 5,
          name: '开题报告',
          txt: '高效、专业、规范的开题助手，语言清晰、逻辑严谨、论据充分',
          img: require('@/assets/images/home/new-home-item2.jpg'),
          url: '/index/open_report'
        },{
          id: 10,
          name: '课程论文',
          txt: '遵循论文的写作规范，加深对课程内容的理解，提高你的写作水平',
          img: require('@/assets/images/home/new-home-item3.jpg'),
          url: '/index/course_paper'
        },{
          id: 13,
          name: '实习报告',
          txt: '你的实习日/周/月记、实践报告、实习总结 小象写作AI帮你搞定',
          img: require('@/assets/images/home/new-home-item4.jpg'),
          url: '/index/internship_report'
        },{
          id: 121,
          name: '案例+PPT',
          txt: '调研千名学生，梳理专业解决方案，一键下载',
          img: require('@/assets/images/home/new-home-item5.jpg'),
          url: '/index/caseLibrary'
        }]
			}
		},
		created() {
      this.isMobile = this.windowWidth < 901;
      this.userList = UserList
      let shareCode = this.$route.query.shareCode
      if(shareCode){
        localStorage.setItem('shareCode',shareCode)
      }
      localStorage.removeItem('currentIndex')
      setTimeout(()=>{
        this.report()
      },500)
    },
    watch: {
      // 监听页面宽度
      windowWidth(newWidth) {
        this.isMobile = newWidth < 901;
      }
    },
    mounted() {
      window.onresize = () => {
        return (() => {
          this.windowWidth = document.documentElement.clientWidth
        })()
      }
      this.getSwiper()
    },
		methods: {
      beianBtn(){
        window.open(this.beianUrl, '_blank');
      },
			goNav(){
        localStorage.setItem('currentIndex','1')
				this.$router.push('/index')
			},
      navBtn(item){
        if(item.id === 0) return
        // this.navList2.forEach(item => item.active = 0)
        // this.navList2[index].active = 1
        this.currentIndex = item.id
        localStorage.setItem('currentIndex',this.currentIndex)
        this.$emit('navName',item.name)
        this.$router.push(item.url)
      },
      toPath(){
        this.currentIndex = 1
        localStorage.setItem('currentIndex',this.currentIndex)
        this.$emit('navName','毕业论文')
        this.$router.push('/index/thesis')
      },
      // 上报日志
      report(){
        let data = {
          type: 0,
          channelCode: '',
          description: ''
        }
        let time = new Date().getTime()
        let key = localStorage.getItem('key')
        let ticket = localStorage.getItem('ticket')
        let setOf = ticket + time + ticket + ticket + time + ticket
        let infoMd5 = this.$md5(setOf)
        RequestlogApi.report(data,key,time,infoMd5).then(res => {
          // console.log(res.data)
        }).catch(() => {
        })
      },
      getSwiper() {
        let sPerView = this.isMobile ? 1 : 3
        this.swiper = new Swiper(".swiper-container", {
          direction: 'horizontal',
          autoplay: {
            delay: 3000,
            disableOnInteraction: false,
            stopOnLastSlide: false,
          },
          slidesPerView: sPerView,
          loop: true,
        })
      },
      closeNotice(){
        this.showNotice = false
      }
		}
	}
</script>

<style lang="less" scoped>
.home-bg{
  width: 100%;
  height: auto;
  min-height: 100%;
  background: url('../../assets/images/home/new-home-bg.jpg') repeat-y center 0;
  background-size: 100% auto;
  .notice{
    position: relative;
    width: 100%;
    height: 44px;
    background: url('../../assets/images/home/new-home-notice.jpg') no-repeat center 0;
    background-size: 100% 44px;
    font-weight: bold;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    span{
      margin-right: 5px;
      color: #e31c1c;
    }
    i{
      position: absolute;
      right: 5%;
      font-size: 22px;
      color: rgba(0,0,0,0.3);
      cursor: pointer;
    }
  }
  .home-heads{
    width: 90%;
    max-width: 1500px;
    height: 60px;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    .logo-img{
      width: 200px;
      padding-left: 30px;
      cursor: pointer;
    }
    .nav-list{
      flex: 1;
      ul{
        margin-left: 60px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        li{
          position: relative;
          margin-right: 40px;
          font-size: 18px;
          font-weight: bold;
          color: #3c3c3c;
          cursor: pointer;
        }
        li::after{
          position: absolute;
          left: 0;
          bottom: -10px;
          content: '';
          width: 0;
          height: 3px;
          background-color: #7bb6fd;
          border-radius: 3px;
          transition: width 0.5s ease-in-out;
        }
        li:hover,.active{
          color: #409EFF;
        }
        li:hover::after,.active::after{
          width: 100%;
        }
      }
    }
  }
  .home-tips{
    width: 743px;
    height: 103px;
    margin: 20px auto 0 auto;
    padding-bottom: 6px;
    font-size: 16px;
    background: url('../../assets/images/home/new-home-tip-bg.png') no-repeat;
    background-size: 743px 103px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    span{
      font-size: 24px;
      font-weight: bold;
      color: #e31c1c;
    }
    span.s1{
      margin-left: 8px;
    }
    span.s2{
      margin-right: 8px;
      font-size: 16px;
    }
  }
  .login-item{
    width: 100%;
    max-width: 1160px;
    margin: 70px auto 0 auto;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    .login-left{
      .login-title{
        width: 448px;
        height: 140px;
        img{
          width: auto;
          height: 100%;
        }
      }
      .login-cont-box{
        width: 600px;
        padding: 30px 20px;
        margin-top: 20px;
        border: 2px solid #ffffff;
        background-color: rgba(255,255,255,0.3);
        border-radius: 15px;
        box-shadow: 0 0 20px rgba(41, 137, 255, 0.18);
        .login-cont{
          li{
            margin-bottom: 20px;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;
            span{
              margin-right: 20px;
              color: #202020;
            }
            span.t{
              width: 80px;
              text-align: right;
              color: #9a9a9a;
            }
          }
          li:last-child{margin-bottom: 0;}
        }
        .login-tip{
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          p{
            margin-left: 10px;
            span{
              color: #df7900;
            }
          }
          .tips-btn{
            position: relative;
            margin-left: auto;
            color: #df7900;
            font-weight: bold;
            cursor: pointer;
          }
          .tips-btn::after{
            content: '';
            position: absolute;
            right: -4px;
            bottom: -4px;
            width: 18px;
            height: 18px;
            border-radius: 20px;
            background-color: rgba(223,121,0,0.1);
          }
          .tips-btn::before{
            content: '';
            position: absolute;
            right: -10px;
            bottom: -10px;
            width: 30px;
            height: 30px;
            border-radius: 30px;
            background-color: rgba(223,121,0,0.1);
          }
          .tips-btn:hover{
            text-decoration: underline;
          }
        }
      }
      .tip-bg{
        padding: 20px;
        background-image: linear-gradient(90deg, #fef7ee, #ffffff);
      }
    }
  }

  .home-title{
    padding: 100px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .title-h1{
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      img{
        width: 30px;
        height: 30px;
        margin: 0 10px;
      }
      span{
        font-size: 40px;
      }
      span.l{
        color: #499dff;
      }
      span.r{
        color: #202020;
      }
      span.ml20{
        margin-left: 20px;
      }
    }
    .title-h2{
      margin-top: 30px;
      font-size: 20px;
      color: #696969;
    }
    .title-btn{
      margin-top: 30px;
      width: 200px;
      height: 60px;
      border-radius: 60px;
      background-color: #489eff;
      cursor: pointer;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      span{
        padding-bottom: 5px;
        margin-right: 5px;
        font-size: 20px;
        color: #ffffff;
      }
      img{
        width: 50px;
        height: 50px;
      }
    }
  }
  .home-foot{
    padding-top: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    > img{
      width: 200px;
      height: 200px;
    }
    p{
      margin-top: 30px;
      color: #202020;
    }
    p.p1{
      font-size: 40px;
    }
    p.p2{
      margin-top: 10px;
      font-size: 24px;
    }
    .title-btn{
      margin-top: 50px;
      width: 200px;
      height: 60px;
      border-radius: 60px;
      background-color: #489eff;
      cursor: pointer;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      span{
        padding-bottom: 5px;
        margin-right: 5px;
        font-size: 20px;
        color: #ffffff;
      }
      img{
        width: 50px;
        height: 50px;
      }
    }
    .footInfoBtn {
      height: 40px;
      line-height: 40px;
      margin-top: 100px;
      cursor: pointer;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      span{
        font-size: 12px;
      }
    }
    .footInfoBtn:hover {
      text-decoration: underline;
      span{
        color: #409EFF;
      }
    }
  }
  .home-cont-box{
    display: flex;
    flex-direction: column;
    align-items: center;
    .home-one{
      width: 94%;
      max-width: 1340px;
      height: 720px;
      background-color: #ffffff;
      border-radius: 15px;
      box-shadow: 0 0 20px rgba(41, 137, 255, 0.18);
      .one-span{
        padding: 20px 0 0 20px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        span{
          width: 20px;
          height: 20px;
          border-radius: 20px;
          margin-right: 10px;
        }
        span:nth-child(1){background-color: #eb6c5d;}
        span:nth-child(2){background-color: #f3bf52;}
        span:nth-child(3){background-color: #68c658;}
      }
      .one-cont{
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: space-evenly;
        .one-item{
          width: 48%;
          .one-txt{
            padding: 60px 0 0 60px;
            h3{
              font-size: 24px;
              font-weight: initial;
              color: #202020;
            }
            > p{
              margin-top: 20px;
              font-size: 16px;
              color: #666666;
            }
            > div{
              margin-top: 30px;
              display: flex;
              flex-direction: row;
              align-items: flex-start;
              justify-content: flex-start;
              img{
                width: 48px;
                height: 48px;
                margin-right: 10px;
              }
              p{
                font-size: 16px;
                color: #202020;
              }
            }
          }
          .one-img{
            width: 100%;
            img{
              width: 100%;
            }
          }
          .one-mt40{
            margin-top: 40px;
          }
        }
      }
    }
    .home-two{
      width: 94%;
      max-width: 1340px;
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: space-evenly;
      .two-left{
        width: 50%;
        padding-top: 70px;
        padding-left: 100px;
        p{color: #202020;margin-bottom: 15px;}
        p.t1{font-size: 32px;margin-left: -50px;}
        p.t2{font-size: 40px;margin-left: 0;}
        p.t3{font-size: 52px;margin-left: 50px;}
        ul{
          padding-left: 50px;
          li{
            margin-top: 30px;
            font-size: 24px;
            color: #202020;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;
            img{
              width: 30px;
              height: 30px;
              margin-right: 20px;
            }
          }
        }
      }
      .two-right{
        width: 50%;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: space-evenly;
        flex-wrap: wrap;
        .two-right-item{
          position: relative;
          width: 248px;
          height: 280px;
          margin-bottom: 30px;
          border-radius: 15px;
          background-color: #ffffff;
          box-shadow: 0 0 20px rgba(41, 137, 255, 0.18);
          overflow: hidden;
          color: #202020;
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          align-items: center;
          h4{
            margin-bottom: 20px;
            z-index: 5;
            font-size: 20px;
          }
          > div{
            z-index: 5;
            margin-bottom: 30px;
            p{
              margin-bottom: 10px;
              font-size: 16px;
            }
          }
        }
        .two-right-item::before{
          z-index: 0;
          content: '';
          position: absolute;
          top: -40px;
          right: -40px;
          width: 200px;
          height: 200px;
          border-radius: 50%;
          background-color: rgba(252,234,206,0.5);
        }
        .two-right-item::after{
          z-index: 0;
          content: '';
          position: absolute;
          bottom: -40px;
          right: -40px;
          width: 200px;
          height: 200px;
          border-radius: 50%;
          background-color: rgba(252,234,206,0.5);
        }
        .col1{
          background-color: #6cb4ff;
          color: #ffffff;
        }
        .col1::before{background-color: rgba(255,255,255,0.3);}
        .col1::after{background-color: rgba(255,255,255,0.3);}
        .col2::before{background-color: rgba(182,218,255,0.5);}
        .col2::after{background-color: rgba(182,218,255,0.5);}
      }
    }
    .home-three {
      width: 94%;
      max-width: 1340px;
      .box{
        .top{
          width: 440px;
          height: 280px;
          background: url('../../assets/images/home/new-home-chat.png') no-repeat;
          background-size: 100% auto;
          padding: 15px 23px 0;
          font-size: 14px;
          color: #404040;
          position: relative;
          .txt{
            margin: 26px 26px 16px 26px;
            line-height: 24px;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            line-clamp: 6;
            -webkit-line-clamp: 6;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .star{
            padding-left: 24px;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;
            i{
              color: #e6a23c;
              font-size: 18px;
            }
          }
        }
        .bottom{
          padding-left: 24px;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: flex-start;
          img{
            width: 64px;
            height: 64px;
            border-radius: 64px;
            border: 2px solid #ffffff;
          }
          > div{
            padding-left: 16px;
            display: flex;
            flex-direction: column;
            span{
              font-size: 12px;
              color: #8c8c8c;
            }
            span.n{
              margin-bottom: 10px;
              font-size: 16px;
              color: #202020;
            }
          }
        }
      }
    }
    .home-four {
      width: 94%;
      max-width: 1340px;
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: space-between;
      .four-item{
        width: 18%;
        max-width: 240px;
        height: 330px;
        background-color: #ffffff;
        border-radius: 15px;
        box-shadow: 0 0 20px rgba(41, 137, 255, 0.18);
        cursor: pointer;
        overflow: hidden;
        .item-title{
          height: 60px;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          span{
            font-size: 24px;
            font-weight: 600;
            color: #202020;
          }
          img{
            width: 26px;
            height: 26px;
          }
        }
        .item-txt{
          height: 100px;
          margin: 10px 20px 0 20px;
          font-size: 16px;
          color: #3c3c3c;
        }
        .item-img{
          text-align: center;
          transform: translateY(30px) rotate(-7.27deg);
          transition: all .3s ease;
          img{
            border-radius: 20px;
            box-shadow: 0 0 20px rgba(41, 137, 255, 0.18);
          }
        }
      }
      .four-item:hover{
        box-shadow: 0 0 20px rgba(41, 137, 255, 0.5);
        .item-img{
          transform: translateY(0) rotate(-7.27deg);
        }
      }
    }
  }
  .suspension-pc-show{
    display: flex;
  }
}
@media screen and (min-width:901px) and (max-width:1400px){
  .home-bg{
    .home-cont-box{
      .home-two{
        .two-left{
          p.t1{font-size: 26px;margin-left: -50px;}
          p.t2{font-size: 32px;margin-left: 0;}
          p.t3{font-size: 44px;margin-left: 50px;}
        }
      }
      .home-three{
        .box {
          .top {
            width: 390px;
            height: 260px;
            .txt {
              margin: 16px 16px 6px 16px;
              line-clamp: 5;
              -webkit-line-clamp: 5;
            }
            .star {
              padding-left: 14px;
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width:900px){
  .home-bg{
    width: 100%;
    height: auto;
    min-height: 100%;
    .notice{
      display: none;
    }
    .home-heads{
      width: 100%;
      .logo-img{
        width: auto;
        padding-left: 10px;
        img{
          width: auto;
          height: 30px;
        }
      }
      .nav-list{
        display: none;
      }
      .wUser{
        background-color: transparent;
      }
    }
    .home-tips{
      width: 100%;
      height: 64px;
      margin-top: 0;
      background-size: 100% 64px;
      font-size: 12px;
    }
    .login-item{
      margin-top: 20px;
      .login-left{
        .login-title{
          width: 90%;
          margin: 0 auto;
          height: 110px;
          img{
            width: 100%;
            height: auto;
          }
        }
        .login-cont-box{
          width: 90%;
          margin: 0 auto 15px auto;
          padding: 10px;
          .login-cont{
            li{
              flex-wrap: wrap;
              padding-left: 60px;
              margin-bottom: 10px;
              span{
                margin-right: 0;
                margin-left: 10px;
                margin-bottom: 5px;
                font-size: 12px;
              }
              span.t{
                width: 60px;
                margin-left: -60px;
              }
            }
          }
          .login-tip{
            p{
              font-size: 12px;
              span{
                font-size: 12px;
              }
            }
            .tips-btn{
              width: 40px;
            }
          }
        }
      }
      .login-container{
        display: none;
      }
    }
    .home-title{
      padding: 50px 0;
      .title-h1{
        span{
          font-size: 20px;
          font-weight: 600;
        }
      }
      .title-h2{
        width: 90%;
        margin: 15px auto 0 auto;
        text-align: center;
        font-size: 16px;
      }
    }
    .home-cont-box{
      .home-one{
        width: 90%;
        height: auto;
        padding-bottom: 30px;
        .one-cont{
          flex-direction: column;
          .one-item{
            width: 100%;
            .one-txt{
              padding: 20px 0 0 20px;
            }
            .one-img{
              width: 100%;
              img{
                width: 100%;
              }
            }
          }
        }
      }
      .home-two{
        width: 90%;
        height: auto;
        flex-direction: column;
        .two-left{
          width: 100%;
          padding-top: 20px;
          padding-left: 30px;
          p{color: #202020;margin-bottom: 8px;}
          p.t1{font-size: 16px;margin-left: -20px;}
          p.t2{font-size: 20px;margin-left: 0;}
          p.t3{font-size: 24px;margin-left: 20px;}
          ul{
            padding-left: 20px;
            li{
              margin-top: 10px;
              font-size: 16px;
              img{
                width: 20px;
                height: 20px;
                margin-right: 10px;
              }
            }
          }
        }
        .two-right{
          width: 100%;
          justify-content: space-between;
          .two-right-item{
            width: 48%;
            height: 210px;
            margin-bottom: 0;
            margin-top: 30px;
          }
          .two-right-item::before{
            width: 160px;
            height: 160px;
          }
          .two-right-item::after{
            width: 160px;
            height: 160px;
          }
        }
      }
      .home-three{
        width: 100%;
        .box {
          .top {
            width: 360px;
            height: 230px;
            margin: 0 auto;
            .txt {
              margin: 16px 16px 6px 16px;
              line-clamp: 5;
              -webkit-line-clamp: 5;
            }
            .star {
              padding-left: 14px;
            }
          }
          .bottom{
            padding-left: 8%;
          }
        }
      }
      .home-four{
        width: 90%;
        margin: 0 auto;
        flex-wrap: wrap;
        .four-item{
          width: 48%;
          height: 220px;
          margin-top: 20px;
          .item-title{
            padding-top: 20px;
            height: 40px;
            span{
              font-size: 16px;
            }
            img{
              width: 16px;
              height: 16px;
            }
          }
          .item-txt{
            height: 66px;
            font-size: 12px;
          }
          .item-img{
            width: 80%;
            margin: 0 auto;
            transform: translateY(20px) rotate(-7.27deg);
            img{
              width: 100%;
              height: auto;
              box-shadow: 0 0 10px rgba(41, 137, 255, 0.18);
            }
          }
        }
      }
    }
    .home-foot{
      p.p1{
        font-size: 26px;
      }
      p.p2{
        font-size: 16px;
      }
    }
    .suspension-pc-show{
      display: none;
    }
  }
}
</style>
