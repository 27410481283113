// 导航菜单的枚举
const navListCodeEnum = {
  THESIS: { path: '/index/thesis', hot: true},
  OPEN_REPORT: { path: '/index/open_report', hot: true},
  REVIEW: { path: '/index/review', hot: false},
  EXPERIENCE_REFLECTION: { path: '/index/experience_reflection', hot: false},
  QUESTIONNAIRE: { path: '/index/questionnaire', hot: false},
  TASK_BOOK: { path: '/index/task_book', hot: true},
  COURSE_PAPER: { path: '/index/course_paper', hot: true},
  THOUGHT_REPORT: { path: '/index/thought_report', hot: false},
  SPEECH: { path: '/index/speech', hot: false},
  INTERNSHIP_REPORT: { path: '/index/internship_report', hot: true},
  SCI: { path: '/index/sci', hot: true},
  PERIODICAL: { path: '/index/journal_article', hot: true},
  AIGC: { path: '/index/aigc', hot: true},
  AIPPT: { path: '/index/ppt_defense', hot: true}
}
export default navListCodeEnum
