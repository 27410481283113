// 导航菜单的枚举
const nameTitleList = {
  THESIS: {
    list: [{
      txt: '支持AI无限改稿：降重、润色、扩写'
    },{
      txt: '每章节均可插入图、表、代码'
    },{
      txt: '知网查重超15%退费'
    },{
      txt: '20篇人工精修参考文献，有需要可找客服领取'
    }]
  },
  OPEN_REPORT: {
    list: [{
      txt: ''
    },{
      txt: ''
    },{
      txt: ''
    },{
      txt: ''
    }]
  },
  REVIEW: {
    list: [{
      txt: ''
    },{
      txt: ''
    },{
      txt: ''
    },{
      txt: ''
    }]
  },
  EXPERIENCE_REFLECTION: {
    list: [{
      txt: ''
    },{
      txt: ''
    },{
      txt: ''
    },{
      txt: ''
    }]
  },
  QUESTIONNAIRE: {
    list: [{
      txt: ''
    },{
      txt: ''
    },{
      txt: ''
    },{
      txt: ''
    }]
  },
  TASK_BOOK: {
    list: [{
      txt: ''
    },{
      txt: ''
    },{
      txt: ''
    },{
      txt: ''
    }]
  },
  COURSE_PAPER: {
    list: [{
      txt: ''
    },{
      txt: ''
    },{
      txt: ''
    },{
      txt: ''
    }]
  },
  THOUGHT_REPORT: {
    list: [{
      txt: ''
    },{
      txt: ''
    },{
      txt: ''
    },{
      txt: ''
    }]
  },
  SPEECH: {
    list: [{
      txt: ''
    },{
      txt: ''
    },{
      txt: ''
    },{
      txt: ''
    }]
  },
  INTERNSHIP_REPORT: {
    list: [{
      txt: ''
    },{
      txt: ''
    },{
      txt: ''
    },{
      txt: ''
    }]
  },
  SCI: {
    list: [{
      txt: ''
    },{
      txt: ''
    },{
      txt: ''
    },{
      txt: ''
    }]
  },
  PERIODICAL: {
    list: [{
      txt: ''
    },{
      txt: ''
    },{
      txt: ''
    },{
      txt: ''
    }]
  },
  AIGC: {
    list: [{
      txt: ''
    },{
      txt: ''
    },{
      txt: ''
    },{
      txt: ''
    }]
  },
  AIPPT: {
    list: [{
      txt: ''
    },{
      txt: ''
    },{
      txt: ''
    },{
      txt: ''
    }]
  }
}
export default nameTitleList
