import request from '@/utils/request'

export default {
  //创建订单(需要Ticket)
  postAigcCreateOrder(data,key,time,md5){
    return request({
      url: '/aigc/create/order',
      method: 'post',
      headers: {
        "ticket-key": key,
        timestamp: time,
        sgin: md5
      },
      data: data
    })
  },
  //获取支持的AIGC查重报告类型
  getAigcReportList(){
    return request({
      url: '/aigc/report/list',
      method: 'get'
    })
  },
  //上传AIGC检查报告(需要Ticket)
  postAigcUpload(headers,formData,key,time,md5){
    return request({
      url: '/aigc/upload',
      method: 'post',
      headers: {
        ...headers,
        "ticket-key": key,
        timestamp: time,
        sgin: md5
      },
      data: formData
    })
  }
}
