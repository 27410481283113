<template>
  <div class="form-box">
<!--    <Collect />-->
    <FormTitle :infoName="'订单查询'" />

    <div class="form-list">

      <div class="form-search">
        <el-form :model="ruleForm" ref="ruleForm" label-position="left" class="demo-ruleForm" @submit.native.prevent>
          <el-form-item label="输入订单号">
            <el-input placeholder="请输入支付时的订单编号，可在支付宝/微信的订单详情中查找"
                      v-model="ruleForm.orderId"
                      type="text"
                      class="input-with-select">
              <el-button slot="append" icon="el-icon-search" @click="submitForm('ruleForm')">查询</el-button>
            </el-input>
          </el-form-item>
        </el-form>
      </div>

      <transition name="fade">
      <div class="case-cont" v-if="myOrderShow">
        <div class="case-list">
          <h3><i class="el-icon-document"></i>{{myOrder.title}}</h3>
          <div>
<!--            <span>{{docName}}</span>-->
<!--            <span>{{modelName}}</span>-->
            <span v-if="myOrder.creationDocName">{{myOrder.creationDocName}}</span>
            <span v-if="myOrder.modelName">{{myOrder.modelName}}</span>
            <span v-if="myOrder.extParam.creationWordsNum">{{myOrder ? myOrder.extParam.creationWordsNum : 0}}字左右</span>
          </div>
        </div>
        <div class="case-txt" v-if="myOrder.otherServerVos && myOrder.otherServerVos.length > 0">
          <div class="case-txt-name">附加服务: </div>
          <div class="case-txt-span">
            <span v-for="item in myOrder.otherServerVos">{{item.otherName}}</span>
          </div>
<!--            {{addServices ? myOrder.otherServerVos : '无'}}-->
<!--          <div v-if="addServices">-->
<!--            <el-button type="text" @click="toPath">无限改稿</el-button>-->
<!--          </div>-->
        </div>
        <div class="case-foot">
          <p>下单时间: {{myOrder.payTime}}</p>
          <p class="case-status">
            <span class="el-but" v-if="addServices && myOrder.status === 2">
              <el-button size="mini" type="success" @click="toPath">AI无限改稿</el-button>
            </span>
            <span class="el-but" v-else-if="addServices && myOrder.status < 2">
              <el-button size="mini" type="info" disabled>AI无限改稿</el-button>
            </span>
<!--            <span :class="'a'+ myOrder.createStatus">{{myOrder.createStatus === -1 ? '任务创建中' : buildStatus[myOrder.createStatus]}}</span>-->
<!--            <span :class="'a'+ myOrder.packStatus">{{packStatus[myOrder.packStatus]}}</span>-->
            <span class="a" :class="'a'+ myOrder.status">{{statusCode[myOrder.status]}}</span>
            <el-button v-if="myOrder.status == 2" type="primary" size="mini" @click="getDownLink(myOrder.id)">下载</el-button>
          </p>
        </div>
      </div>
      </transition>

      <div v-if="showEmpty">
        <el-empty description="未找到订单信息"></el-empty>
      </div>

      <OrderExamples :activeNamesValue="activeNames"></OrderExamples>

      <div class="order-tips">
        <p class="h3">温馨提示：</p>
        <p>1.系统保留7天以内的订单，超过7天则被删除，确保您的论文不会被覆盖和盗窃，完成后请尽快下载！</p>
        <p>2.付款后订单在10-30分钟内完成，请耐心等候</p>
      </div>

      <NoticeWrapper />

    </div>
  </div>
</template>

<script>
  import CommonApi from "@/api/common"
  import OrderExamples from '@/components/OrderExamples/OrderExamples.vue'
  import FormTitle from "@/components/FormTitle/index.vue"
  // import Collect from "@/components/collect"
  import NoticeWrapper from "@/components/NoticeWrapper/index.vue"
	export default {
    components:{
      NoticeWrapper,
      FormTitle,
      OrderExamples,
      // Collect
    },
    props: ['infoName'],
		data() {
			return {
        ruleForm: {
          orderId: ''
        },
        // rules: {
        //   orderId: [
        //     { required: true, message: '请输入订单号', trigger: 'blur' },
        //   ]
        // },
        activeNames: ['1'], // 传给组件默认值
        myOrder: '',
        creationDoc: '',
        modelCode: '',
        // docName: '',
        // modelName: '',
        myOrderShow: false,
        // buildStatus: ['待生成','生成中','已生成'],
        // packStatus: ['待打包','打包中','已打包'],
        statusCode: ['','生成中','已生成'],
        addServices: false,
        countdown: 0, // 设置倒计时
        timer: null,  //定时器
        showEmpty: false, //空状态
			}
		},
		created() {
      localStorage.setItem('currentIndex', 120)
    },
    beforeDestroy() {
      this.clearCountdown()
    },
    mounted() {
      let orderInfo = this.$route.query
      if(orderInfo.info){
        this.ruleForm.orderId = orderInfo.info
        this.activeNames = 2
        this.queryOrder(orderInfo.info)
      }
    },
		methods: {
      // 订单查询
      queryOrder(orderNo){
        let _this = this
        CommonApi.queryOrder(orderNo).then(res => {
          _this.myOrder = res.data
          // console.log(_this.myOrder.extPara)
          if(_this.myOrder.extParam){
            let info = JSON.parse(_this.myOrder.extParam)
            _this.myOrder.extParam = info
          }
          if(_this.myOrder.otherServerVos.length > 0){
            _this.addServices = _this.myOrder.otherServerVos.some(item => item.taskTag === 'AI_REWRITE' )
          }else {
            _this.addServices = false
          }
          _this.showEmpty = false
          _this.myOrderShow = true
          if(_this.myOrder.status == 2){
            _this.msgInfo()
            _this.msgTimeLineNumber()
            // 清除定时器
            _this.clearCountdown();
          }else {
            // 启动查询定时器
            _this.startCountdown()
          }
        }).catch(() => {
          _this.showEmpty = true
          _this.myOrderShow = false
        })
      },
      submitForm(formName) {
        let _this = this

        if(_this.ruleForm.orderId === ''){
          return _this.$message({
            message: '请输入订单号',
            type: 'warning',
            duration: 2000,
            customClass: "focus-message",
          });
        }

        _this.queryOrder(_this.ruleForm.orderId)

        // this.$refs[formName].validate((valid) => {
        //   if (valid) {
        //     _this.queryOrder(_this.ruleForm.orderId)
        //   } else {
        //     return false;
        //   }
        // });
      },
      // resetForm(formName) {
      //   this.$refs[formName].resetFields();
      // },
      // 获取下载链接
      getDownLink(id){
        let time = new Date().getTime()
        let key = localStorage.getItem('key')
        let ticket = localStorage.getItem('ticket')
        let setOf = ticket + time + ticket + ticket + time + ticket
        let infoMd5 = this.$md5(setOf)
        CommonApi.getDownLink(id,key,time,infoMd5).then(res => {
          // console.log(res.data)
          window.location.href = res.data;
        }).catch(() => {
        })
      },
      // 开始倒计时
      startCountdown() {
        if (this.countdown === 0) {
          // 初始化倒计时
          this.countdown = 30;
          // 设置定时器
          this.timer = setInterval(() => {
            if (this.countdown > 0) {
              this.countdown--;
            } else {
              // 清除定时器
              this.clearCountdown();
              // 倒计时结束，调用方法
              this.countdownEnded();
            }
          }, 1000); // 每秒执行一次
        }
      },
      // 倒计时结束后的方法
      countdownEnded() {
        let _this = this
        // console.log('倒计时结束');
        // 这里可以添加你需要在倒计时结束后执行的方法
        _this.queryOrder(_this.ruleForm.orderId)
      },
      // 清除定时器
      clearCountdown() {
        if (this.timer) {
          clearInterval(this.timer);
          this.timer = null;
          this.countdown = 0; // 可选：将倒计时重置为0
        }
      },
      msgInfo() {
        this.$confirm('', '订单已生成', {
          confirmButtonText: '确定',
          roundButton: true,
          showCancelButton: false,
          center: true
        });
      },
      msgTimeLineNumber() {
        this.$emit('TimeLineNumber', 4);
      },
      toPath(){
        // this.$router.push('/ai_revision?orderId=' + this.ruleForm.orderId)
        let info = {
          id: this.myOrder.id,
          title: this.myOrder.title
        }
        this.$router.push({name: 'ai_revision', params: info})
      }
		}
	}
</script>

<style lang="less" scoped>
  .form-box{
    position: relative;
    width: 100%;
    height: 100%;

    .form-list{
      width: 94%;
      max-width: 1000px;
      height: 90%;
      margin: 0 auto;
      //overflow: auto;
      //padding: 30px 5% 10px 5%;
      .form-search{
        background: rgb(255, 255, 255);
        box-shadow: rgba(0, 0, 0, 0.06) 0 2px 8px 1px;
        border-radius: 8px;
        border: 1px solid rgb(228, 229, 231);
        padding: 20px 30px;
        .el-form-item{
          margin: 0;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          /deep/ .el-form-item__content{
            flex: 1;
            .el-button{
              border-radius: 0 3px 3px 0;
              color: #ffffff;
              background-color: #409EFF;
            }
          }
        }
      }
      .fade-enter-active,.fade-leave-active{
          transition: opacity 3s
      }
      .fade-enter, .fade-leave-to{
          opacity: 0
      }
      .case-cont{
        width: 100%;
        margin-top: 20px;
        border-radius: 15px;
        border: 1px solid #d3dce6;
        transition: all 1s;
        .case-list{
          padding: 0 20px;
          height: 60px;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          border-bottom: 1px solid #d3dce6;
          h3{
            font-size: 14px;
            font-weight: bold;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            i{
              margin-right: 5px;
              color: #409EFF;
              font-size: 18px;
            }
          }
          p{
            margin: 12px 20px 12px 20px;
            height: 44px;
            font-size: 14px;
            line-height: 22px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
          }
          >div{
            margin-left: 20px;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;
            span{
              padding: 2px 5px;
              margin-left: 10px;
              font-size: 12px;
              font-weight: normal;
              color: #ffffff;
              border-radius: 3px;
            }
            span:nth-child(1){background-color: #409EFF;}
            span:nth-child(2){background-color: #67C23A;}
            span:nth-child(3){background-color: #E6A23C;}
          }
        }
        .case-txt{
          padding: 20px;
          display: flex;
          flex-direction: row;
          align-items: flex-start;
          justify-content: flex-start;
          border-bottom: 1px solid #d3dce6;
          .case-txt-name{
            width: 70px;
            line-height: 40px;
          }
          .case-txt-span{
            flex: 1;
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            justify-content: flex-start;
            flex-wrap: wrap;
            span{
              padding: 10px 20px;
              margin-left: 15px;
              margin-bottom: 15px;
              background: linear-gradient(90deg, #eef9fe, #fcfeff);
              border-radius: 6px;
              border: 1px solid #e9f7ff;
              color: #2c2c2c;
            }
          }
        }
        .case-foot{
          padding: 0 20px;
          height: 60px;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          .case-status{
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            span.a{
              padding: 2px 5px;
              margin-right: 15px;
              font-size: 12px;
              font-weight: normal;
              border-radius: 3px;
              border: 1px solid #ffffff;
            }
            span.a0{
              background-color: #f4f4f5;
              border-color: #e9e9eb;
              color: #909399;
            }
            span.a1{
              background-color: #fdf6ec;
              border-color: #faecd8;
              color: #e6a23c;
            }
            span.a2{
              background-color: #ecf5ff;
              border-color: #d9ecff;
              color: #409eff;
            }
            span.el-but{
              margin-right: 20px;
            }
          }
        }
        .el-carousel__container{
          height: 150px !important;
        }
      }
      .order-tips{
        padding: 20px 0;
        p{
          line-height: 24px;
          font-size: 14px;
          color: #656b76;
        }
        p.h3{
          padding-bottom: 10px;
          font-size: 14px;
          font-weight: bold;
        }
      }
    }
  }

  @media screen and (max-width: 900px) {
    .form-box{
      height: 100%;
      .form-list{
        width: 100%;
        height: auto;
        min-height: 100%;
        padding: 15px;
        margin-top: 10px;
        background-color: rgba(255,255,255,0.7);
        box-shadow: 0 2px 5px rgba(0, 85, 255, 0.1);
        border-radius: 10px;
        .form-search{
          background: none;
          box-shadow: none;
          border-radius: 8px;
          border: 0;
          padding: 0;
          .el-form-item{
            margin: 0;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            /deep/ .el-form-item__content{
              .el-button{
                width: 70px;
                height: 40px;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;
                i{
                  font-size: 12px !important;
                }
                span{
                  font-size: 12px !important;
                }
              }
            }
          }
        }
        .order-tips{
          p{
            font-size: 12px;
            line-height: 20px;
          }
          p.h3{
            font-size: 12px;
          }
        }
        .case-cont{
          background-color: #ffffff;
          .case-list{
            padding: 10px;
            height: auto;
            flex-direction: column;
            align-items: flex-start;
            h3{
              align-items: flex-start;
              overflow: initial;
              text-overflow: initial;
              white-space: initial;
            }
            >div{
              margin-left: 0;
              margin-top: 10px;
              align-items: flex-start;
              span{
                margin-left: 0;
                margin-right: 6px;
                font-size: 10px;
              }
            }
          }
          .case-txt{
            padding: 10px;
            flex-direction: column;
            align-items: flex-start;
            >div{
              font-size: 12px;
            }
            .case-txt-span{
              padding: 10px 0 0 0;
              span{
                display: block;
                padding: 7px 10px;
                margin: 0 10px 10px 0;
                font-size: 11px;
              }
            }
          }
          .case-foot{
            padding: 10px;
            height: auto;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;
            >p{
              font-size: 12px;
            }
            .case-status{
              margin-left: auto;
              span.a{
                padding: 2px 3px;
                margin-right: 8px;
                font-size: 11px;
              }
              span.el-but{
                margin-right: 10px;
                .el-button--mini{
                  padding: 4px 5px !important;
                  ///deep/ span{
                  //  font-size: 11px !important;
                  //}
                }
              }
            }
          }
        }
      }
    }
  }
</style>
