<template>
  <div class="header">
    <div class="logo-img" @click="goHome">
      <img src="@/assets/images/logo_h.png" alt="小象写作">
    </div>
    <div class="nav-list">
      <ul class="nav">
        <li :class="{'active':item.id == currentIndex || (index === 0 && showIndex) }" v-for="(item,index) in navList2" @click="navBtn(item,index)">{{item.name}}</li>
      </ul>
    </div>
    <!-- 多功能菜单 -->
<!--    <div class="fun-list">-->
<!--      <div v-for="(item,index) in funList" @click="btnOn(item.url,item.name)">-->
<!--        <span :class="index > 1 ? 'not' : ''">{{item.name}}</span>-->
<!--        <img :src="item.img" alt="" />-->
<!--      </div>-->
<!--    </div>-->
<!--    <div class="collect-btn" @click="toPath">-->
<!--      <i class="el-icon-star-off"></i>-->
<!--      <span>AI无限改稿</span>-->
<!--    </div>-->
<!--    <div class="collect-btn" @click="openCollect">-->
<!--      <i class="el-icon-star-off"></i>-->
<!--      <span>收藏本站</span>-->
<!--    </div>-->
    <Collect />
    <div class="number-people">
      已有<span class="n1">138</span><span class="n2">万+</span>用户使用
    </div>
    <UserLogin></UserLogin>
  </div>
</template>
<script>
import UserLogin from "@/components/userLogin/index.vue"
import Collect from "@/components/collect/index.vue"

export default {
  name: 'CaseHeads',
  components: {Collect, UserLogin},
  props: ['activeValue'],
  data() {
    return {
      currentIndex: localStorage.getItem('currentIndex') || 0,
      navList2: [{
        active: 0,
        id: 110,
        name: 'AI写作',
        url: '/index'
      },{
        active: 1,
        id: 121,
        name: '案例库',
        url: '/index/caseLibrary'
      },{
        active: 2,
        id: 122,
        name: 'PPT模板',
        url: '/index/pptLibrary'
      },{
        active: 3,
        id: 120,
        name: '订单查询',
        url: '/index/order'
      }],
      // funList: [{
      //   name: '订单查询',
      //   url: '/index/order',
      //   img: require('@/assets/images/ddcx.jpg')
      // }],
      showIndex: false
    }
  },
  watch:{
    $route(to,from){
      let _this = this
      if(_this.navList2.length > 0){
        _this.navList2.some(item => {
          if(to.path === item.url){
            _this.currentIndex = item.id
            localStorage.setItem('currentIndex',_this.currentIndex)
            // _this.$emit('navName',item.name)
          }
        })
      }
      setTimeout(()=>{
        _this.currentIndex = localStorage.getItem('currentIndex')
        _this.countIndex()
      },200)
    }
  },
  mounted() {
    setTimeout(()=>{
      this.countIndex()
    },200)
  },
  methods: {
    navBtn(item,index){
      if(this.$route.path === '/index/thesis' && item.url === '/index') return
      this.navList2.forEach(item => item.active = 0)
      this.navList2[index].active = 1
      this.currentIndex = item.id
      localStorage.setItem('currentIndex',this.currentIndex)
      this.$emit('navName',item.name)
      this.$router.push(item.url)
    },
    goHome(){
      this.$router.push('/')
    },
    btnOn(url){
      this.$router.push(url)
    },
    countIndex(){
      let showId = this.navList2[0].id
      let condition1 = showId >= this.currentIndex
      let condition2 = showId <= 110
      this.showIndex = (condition1 && condition2)
    },
    openCollect(){
      alert('请按键盘 Ctrl+D (Windows) 或 Command+D (Mac) 将此页面添加到您的收藏夹。')
    },
    toPath(){
      this.$router.push('/ai_revision')
    }
  }
}
</script>
<style scoped lang="less">
  .header{
    position: absolute;
    z-index: 999;
    top: 0;
    left: 0;
    width: 100%;
    height: 60px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    background-color: #ffffff;
    box-shadow: 0 0 15px rgba(0, 85, 255, 0.15);
    .logo-img{
      width: 200px;
      padding-left: 30px;
      cursor: pointer;
    }
    .nav-list{
      flex: 1;
      ul{
        margin-left: 60px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        li{
          position: relative;
          margin-right: 40px;
          font-size: 18px;
          font-weight: bold;
          color: #3c3c3c;
          cursor: pointer;
        }
        li::after{
          position: absolute;
          left: 0;
          bottom: -10px;
          content: '';
          width: 0;
          height: 3px;
          background-color: #7bb6fd;
          border-radius: 3px;
          transition: width 0.5s ease-in-out;
        }
        li:hover,.active{
          color: #409EFF;
        }
        li:hover::after,.active::after{
          width: 100%;
        }
      }
    }
    .collect-btn{
      padding: 5px 10px;
      margin-right: 20px;
      cursor: pointer;
      display: flex;
      i{
        margin-right: 5px;
        font-size: 18px;
        color: #e6a23c;
      }
      span{
        font-size: 14px;
      }
    }
    .number-people{
      width: 140px;
      height: 28px;
      line-height: 28px;
      border-radius: 28px;
      text-align: center;
      box-shadow: 0 0 5px rgba(255, 129, 0, 0.2);
      font-size: 12px;
      color: #3c3c3c;
      span.n1{
        margin: 0 0 0 4px;
        font-size: 16px;
        font-weight: bold;
        color: #d90202;
      }
      span.n2{
        margin: 0 4px 0 0;
        font-size: 12px;
        color: #d90202;
      }
    }
    //.fun-list {
    //  margin-left: auto;
    //  margin-right: 40px;
    //  >div {
    //    width: 146px;
    //    height: 44px;
    //    padding: 0 5px;
    //    background-color: #ffffff;
    //    border-radius: 5px;
    //    box-shadow: 0 0 10px rgba(0, 85, 255, 0.2);
    //    cursor: pointer;
    //    overflow: hidden;
    //    display: flex;
    //    flex-direction: row;
    //    align-items: center;
    //    justify-content: space-between;
    //    img {
    //      width: auto;
    //      height: 40px;
    //      margin-right: 6px;
    //    }
    //    span {
    //      position: relative;
    //      margin: 0 0 0 30px;
    //    }
    //    span::after {
    //      content: '';
    //      display: block;
    //      position: absolute;
    //      width: 10px;
    //      height: 10px;
    //      top: 4px;
    //      left: -22px;
    //      border-top: 2px solid;
    //      border-right: 2px solid;
    //      border-color: #85d7fb;
    //      transform: rotate(45deg);
    //    }
    //    span.not::after {
    //      border-color: #c0c0c0;
    //    }
    //  }
    //}
  }
  @media screen and (min-width: 1350px) and (max-width: 1602px){
    .header{
      .nav-list{
        ul{
          li{
            margin-right: 10%;
            font-size: 16px;
          }
        }
      }
    }
  }
  @media screen and (min-width: 900px) and (max-width: 1349px){
    .header{
      .nav-list{
        ul{
          li{
            margin-right: 6%;
            font-size: 15px;
          }
        }
      }
    }
  }
</style>
